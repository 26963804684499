import http from "@/http/config";

const PROJECT_URL = "/portfolio"

async function findAll() {
    return await http.get(PROJECT_URL);
}

async function findOne(code) {
    return await http.get(`${PROJECT_URL}/${code}`);
}

export default {
    findAll,
    findOne
};
